// import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/reset.css'
import './assets/iconfont/iconfont.css'

import store from './store'

Vue.config.productionTip = false
String.prototype.trim = function () {
    return this.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
}
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')


