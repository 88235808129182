<template>
  <div id="app">
    <page-header></page-header>
    <router-view></router-view>
  </div>
</template>

<script>
import PageHeader from "@/components/Header"
export default {
  name: 'App',
  components:{
    PageHeader
  },
  watch: {
    '$route'(to, from) {
      document.title = to.meta.title || 'LKVAL'
    }
  },
}
</script>

<style>
</style>
