import Router from 'vue-router'
import QC from 'QC'
import axios from 'axios'
import store from '../store/index'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)


const router = new Router({
    routes: [
        {
            path: "/qqlogin",
            component: () => import('@/components/QQlogin.vue')
        },
        // val的主页面
        {
            path: "/",
            component: () => import('@/components/Home.vue'),
            meta: {
                title: 'LKVAL-无畏契约点位速查'
            }
        },
        // cs2的主页面
        {
            path: "/cs2",
            component: () => import('@/components/Cs2.vue'),
            meta: {
                title: 'LKCS2-CS2道具点位速查'
            }
        },
        {
            path: "/test",
            component: () => import('@/components/test.vue'),
            meta: {
                title: '测试'
            }
        },
        {
            path: "/pov",
            component: () => import('@/components/Pov.vue'),
            meta: {
                title: 'POV'
            }
        },
        {
            path: "/comment",
            component: () => import('@/components/Comment.vue'),
            meta: {
                title: '评论'
            }
        },
        {
            path: "/lineup",
            component: () => import('@/components/Lineup.vue'),
            meta: {
                title: '评论'
            }
        },
        {
            path: "/discuss",
            component: () => import('@/components/Discuss.vue'),
            meta: {
                title: '上传点位'
            }
        },
        {
            path: "/edit_profile",
            component: () => import('@/components/EditProfile.vue'),
            meta: {
                title: '修改昵称'
            }
        },
        {
            path: "/discuss/:blogId",
            component: () => import('@/components/DiscussDetail.vue'),
            meta: {
                title: '点位详情'
            }
        },
        {
            path: "/:lineupId",
            component: () => import('@/components/DiscussDetail.vue'),
            meta: {
                title: '点位详情'
            }
        },
    ],
    mode: "history"
})

router.beforeEach((to, from, next) => {
    document.querySelector('body').setAttribute('style', 'background:#f1f2f5;')
    //在这里做cs2和val的判断
    let currentPage = localStorage.getItem('currentPage')
    if (currentPage) {
        store.commit('setCurrentPage', currentPage)
    }
    // token检测
    let localToken = localStorage.getItem('token')
    if (localToken) { // 本地已经存储了token，检测一下是否过期
        axios({
            method: 'post',
            url: '/api/users/login',
            data: {
                access_token: localToken,
                openid: '',
            }
        }).then(r => {
            if (r.data.code === 0) { //本地token没有问题
                store.commit('setUserInfo', {
                    nickname: r.data.nickname,
                    figureurl_qq_1: r.data.figureurl_qq_1
                })
                store.commit('setIsAdmin', r.data.is_admin)
                axios.defaults.headers.common["Authorization"] = localToken
                next()
            } else { //token有问题
                delete axios.defaults.headers.common.Authorization
                localStorage.removeItem('token')
                store.commit('resetState')
            }
        })
    }
    if (QC.Login.check()) {
        QC.Login.getMe(function (openId, accessToken) {
            localStorage.setItem('token', accessToken)
            QC.api('get_user_info').success(function a(res) {
                store.commit('setUserInfo', {
                    nickname: res.data.nickname,
                    figureurl_qq_1: res.data.figureurl_qq_1
                })
                axios.defaults.headers.common["Authorization"] = accessToken
                axios({
                    method: 'post',
                    url: '/api/users/login',
                    data: {
                        access_token: accessToken,
                        openid: openId,
                        nickname: res.data.nickname,
                        figureurl_qq_1: res.data.figureurl_qq_1
                    }
                }).then(r => {
                    store.commit('setIsAdmin', r.data.is_admin)
                })
            })
        })
    } else {
        console.log('route qc check false')
    }
    next()
})


export default router