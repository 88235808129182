<template>
  <div class="header-container">
    <template v-if="isPhoneWidth">
      <!--      <div class="header-phone-wrapper">-->
      <!--        <div class="header-phone-logo">-->
      <!--          <a href="/" style="color: white; text-decoration:none;">LKVAL</a>-->
      <!--        </div>-->
      <!--        <div class="header-phone-menu" @click="showPhoneMenu">-->
      <!--          <i class="iconfont icon-caidan"></i>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="header-phone-nav" v-show="phoneMenuVisible">-->
      <!--        <template v-for="(item, index) in ROUTER_LINKS">-->
      <!--          <router-link :to="item.link" tag="li"-->
      <!--                       :class="pathname===item.link? 'header-nav-li-active':''" :key="index" exact>-->
      <!--            {{ item.title }}-->
      <!--          </router-link>-->
      <!--        </template>-->
      <!--        &lt;!&ndash;        <div style="margin-bottom: 10px;"></div>&ndash;&gt;-->
      <!--        &lt;!&ndash;        <div class="header-streamer-title">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <button class="header-streamer-title-btn" onclick="window.open('https://space.bilibili.com/53619890')">&ndash;&gt;-->
      <!--        &lt;!&ndash;            B站主页:龍克&ndash;&gt;-->
      <!--        &lt;!&ndash;          </button>&ndash;&gt;-->
      <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
      <!--        <div style="margin-bottom: 10px;"></div>-->
      <!--        <template v-if="$store.state.userinfo.figureurl_qq_1">-->
      <!--          <el-dropdown @command="handleCommand">-->
      <!--            <div class="el-dropdown-link header-login-avatar">-->
      <!--              <el-avatar :src="$store.state.userinfo.figureurl_qq_1"></el-avatar>-->
      <!--              <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
      <!--            </div>-->
      <!--            <el-dropdown-menu slot="dropdown">-->
      <!--              <el-dropdown-item disabled>{{ $store.state.userinfo.nickname }}</el-dropdown-item>-->
      <!--              <el-dropdown-item command="editProfile">修改昵称</el-dropdown-item>-->
      <!--              <el-dropdown-item command="signOut" divided>退出登录</el-dropdown-item>-->
      <!--            </el-dropdown-menu>-->
      <!--          </el-dropdown>-->
      <!--        </template>-->
      <!--        <template v-else>-->
      <!--          <el-button type="primary" style="margin-right: 40px" size="small" @click="openQQLogin">-->
      <!--            QQ登录-->
      <!--          </el-button>-->
      <!--        </template>-->
      <!--      </div>-->

    </template>
    <template v-else>
      <div class="header-wrapper">
        <div class="header-logo">
          <a @click="toHomePage" style="color: #000000; text-decoration:none;font-weight:800;">LK</a>
        </div>
        <ul class="header-nav">
          <template v-if="$store.state.currentPage === 'val'">
            <router-link :to="'/'" tag="li"
                         :class="pathname==='/'? 'header-nav-li-active':''">
              LINEUPS
            </router-link>
          </template>
          <template v-else>
            <router-link :to="'/cs2'" tag="li"
                         :class="pathname==='/cs2'? 'header-nav-li-active':''">
              道具点位
            </router-link>
          </template>
          <router-link :to="'/discuss'" tag="li"
                       :class="pathname==='/discuss'? 'header-nav-li-active':''">
            上传点位
          </router-link>
          <router-link v-if="$store.state.userinfo.isAdmin"
                       :to="'/comment'" tag="li"
                       :class="pathname==='/comment'? 'header-nav-li-active':''">
            评论管理
          </router-link>
          <router-link v-if="$store.state.userinfo.isAdmin"
                       :to="'/lineup'" tag="li"
                       :class="pathname==='/lineup'? 'header-nav-li-active':''">
            点位管理
          </router-link>
        </ul>
        <el-divider direction="vertical"></el-divider>
        <div class="header-streamer-title">
          <template v-if="$store.state.currentPage === 'cs2'">
            <a @click="selectGame('val')" style="margin-right: 10px">
              切换至无畏契约
            </a>
          </template>
          <template v-else>
            <a @click="selectGame('cs2')" style="margin-right: 10px">
              切换至CS2点位
            </a>
          </template>

          <a onclick="window.open('https://space.bilibili.com/53619890')" style="margin-right: 20px">
            联系作者:龍克
          </a>

          <div class="live-card">
            <a onclick="window.open('https://www.douyu.com/8694')">
              冠名主播:Sideny蛇
            </a>
          </div>
        </div>
        <template v-if="$store.state.userinfo.figureurl_qq_1">
          <el-dropdown @command="handleCommand">
            <div class="el-dropdown-link header-login-avatar">
              <el-avatar :src="$store.state.userinfo.figureurl_qq_1"></el-avatar>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item disabled>{{ $store.state.userinfo.nickname }}</el-dropdown-item>
              <el-dropdown-item command="editProfile">修改昵称</el-dropdown-item>
              <el-dropdown-item command="signOut" divided>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template v-else>
          <el-button type="primary" style="margin-right: 40px" size="small" @click="openQQLogin">
            QQ登录
          </el-button>
        </template>
      </div>
    </template>

  </div>
</template>

<script>
import QC from 'QC'
import axios from 'axios'
import store from "@/store";

const PHONE_WIDTH = 768
export default {
  name: "Header",
  data() {
    return {
      isPhoneWidth: false,
      isLive: false,
      phoneMenuVisible: false,
      pathname: '/',
    }
  },
  created() {
    this.handleWindowWidth()
    // window.addEventListener('resize', this.handleWindowWidth)
    // this.getLiveStatus()
  },

  destroyed() {
    // window.addEventListener('resize', this.handleWindowWidth)
  },
  watch: {
    $route(to, from) {
      this.handlePathname()
    },
  },
  methods: {
    toHomePage() {
      if (store.state.currentPage === 'val') {
        this.$router.push("/");
      } else if (store.state.currentPage === 'cs2') {
        this.$router.push("/cs2");
      } else {
        this.$router.push("/");
      }
    },
    handleWindowWidth() {
      this.isPhoneWidth = window.innerWidth <= PHONE_WIDTH;
    },
    handlePathname() {
      this.pathname = window.location.pathname
    },
    showPhoneMenu() {
      this.phoneMenuVisible = !this.phoneMenuVisible
    },
    handleCommand(command) {
      if (command === 'signOut') {
        this.signOut()
      }
      if (command === 'editProfile') {
        this.$router.push('/edit_profile')
      }
    },
    signOut() {
      QC.Login.signOut()
      delete axios.defaults.headers.common.Authorization
      localStorage.removeItem('token')
      this.$store.commit('resetState')
      location.reload()
    },
    openQQLogin() {
      localStorage.setItem('urlBeforeLogin', window.location.href)
      window.location = 'https://graph.qq.com/oauth2.0/authorize?response_type=token&client_id=102047093&redirect_uri=https://lkval.com/qqlogin'
    },
    getLiveStatus() {
      axios({
        method: 'get',
        url: '/api/blogs/is_live',
      }).then(r => {
        if (r.data.code === 0) {
          this.isLive = r.data.is_live
        }
      })
    },
    openLive() {
      window.open('https://live.bilibili.com/7982425')
    },
    selectGame(game) {
      if (game === 'cs2') {
        store.commit('setCurrentPage', 'cs2')
        localStorage.setItem('currentPage', 'cs2')
        this.$router.push("/cs2");
      } else {
        store.commit('setCurrentPage', 'val')
        localStorage.setItem('currentPage', 'val')
        this.$router.push("/");
      }
    }
  }
}
</script>
x
<style scoped>

.header-container {
  background: #ffffff;
}

.header-wrapper {
  margin: 0 auto;
  height: 52px;
  min-width: 700px;
  display: flex;
  align-items: center;
}

.header-logo {
  margin-left: 40px;
  margin-right: 40px;
  font-size: 38px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-log a {
  color: #000000;
}

.header-nav {
  margin: 0;
  height: 100%;
  display: flex;
  cursor: pointer;
}

.header-nav li {
  padding: 10px;
  display: flex;
  align-items: center;

  color: #bfdbfd;

  border-bottom: 2px white solid;
}

.header-nav li:hover {
  color: #4ba0fb;
  border-bottom: 2px #409eff solid;
}

.header-nav .header-nav-li-active {
  color: #4ba0fb;
  border-bottom: 2px #409eff solid;
}

.header-streamer-title {
  display: flex;
  flex-grow: 1;
  color: #b1b1b1;
}

.header-streamer-title a:hover {
  color: #4ba0fb;
  cursor: pointer;
}

.header-streamer-title-btn {
  padding: 2px 12px;
  font-size: 20px;
  border: 1px #ff4655 solid;
  border-radius: 3px;
  color: white;
  background: #ff4655;
  cursor: pointer;
}

.header-btn {
  margin-right: 40px;
  display: flex;
}

.header-btn-login {
  width: 80px;
  height: 32px;
  border: 1px #0066ff solid;
  border-radius: 3px;
  color: white;
  background: #0066ff;
  cursor: pointer;
}

.header-btn-is-login {
  margin-right: 40px;
}

.header-phone-wrapper {
  margin: 0 auto;
  height: 52px;
  display: flex;
  align-items: center;
}

.header-phone-logo {
  margin-left: 20px;
  font-size: 38px;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.header-phone-menu {
  margin-right: 20px;
  font-size: 38px;
  width: 40px;
  height: 40px;
  border: 1px #000000 solid;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header-phone-menu i {
  font-size: 30px;
  color: #000000;
}

.header-phone-nav {
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.header-phone-nav li {
  padding: 10px 0;
  display: flex;
  align-items: center;
  color: #000000;

  border-bottom: 2px #0f1923 solid;
}

.header-phone-nav li:hover {
  color: #000000;
  border-bottom: 2px #3f474f solid;
}

.header-phone-nav .header-nav-li-active {
  color: #000000;
  border-bottom: 2px #ff4655 solid;
}

.el-dropdown-link {
  cursor: pointer;
  color: #000000;
  display: flex;
  align-items: center;
}

.header-login-avatar {
  margin-right: 40px;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.live-card {
  border: 1px solid #ff6699;
  border-radius: 4px;
  color: #ff6699;
  padding: 0 4px;
  cursor: pointer;
}
</style>