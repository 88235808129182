import Vuex from 'vuex'

Vue.use(Vuex)


const getDefaultState = () => {
    return {
        count: 0,
        token: '',
        userinfo: {},
    }
}

export default new Vuex.Store({
    state: {
        count: 0,
        token: '',
        userinfo: {},
        isAdmin: false,
        currentPage: 'val',
    },
    mutations: {
        increment(state) {
            state.count++
        },
        setUserInfo(state, userinfo) {
            Vue.set(state.userinfo, 'nickname', userinfo.nickname)
            Vue.set(state.userinfo, 'figureurl_qq_1', userinfo.figureurl_qq_1)
        },
        setIsAdmin(state, isAdmin) {
            Vue.set(state.userinfo, 'isAdmin', isAdmin)
        },
        setCurrentPage(state, page) {
            state.currentPage = page
        },
        resetState(state) {
            Object.assign(state, getDefaultState())
        }
    }
})